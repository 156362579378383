<template>
    <div class="d-flex gap-3 flex-column">
        <h1 class="fw-bold">Create Account</h1>
        <span>Tatsing Group > Create Account</span>

        <div class="card shadow-sm d-flex flex-column justify-content-center">
            <PreloaderVue v-show="isLoading" />
            <div class="card py-3 px-4">
                <h4 class="fw-bold">Account details</h4>
            </div>
            <form @submit.prevent="createAccount">
                
                <div class="card py-3 px-4">
                    <div class="pb-2" :class="{'text-success': message.toLowerCase().includes('success'),'text-danger': !message.toLowerCase().includes('success')}"
                    v-show="message">
                        {{ message }}
                    </div>
                    <div class="d-flex align-items-center flex-column flex-md-row w-100 gap-4">
                        <div class="w-100 d-flex flex-column">
                            <input v-model="user_data.full_name" type="text"
                             placeholder="Name" class="mb-3 w-100 border rounded py-2 px-4" required autocomplete="nope">
                            <div class="text-danger" v-show="errors.full_name">
                                <ul>
                                    <li v-for="item in errors.full_name" :key="item">
                                        {{ item }}
                                    </li>
                                </ul>
                            </div>
                            
                            <input v-model="user_data.username" type="text"
                             placeholder="Username" class="mb-3 w-100 border rounded py-2 px-4" required autocomplete="nope">
                            <div class="text-danger align-middle" v-show="errors.username">
                                <ul>
                                    <li v-for="item in errors.username" :key="item">
                                        {{ item }}
                                    </li>
                                </ul>
                            </div>
                            
                            <input v-model="user_data.email" type="email"
                             placeholder="Email" class="mb-3 w-100 border rounded py-2 px-4" required autocomplete="nope">
                            <div class="text-danger" v-show="errors.email">
                                <ul>
                                    <li v-for="item in errors.email" :key="item">
                                        {{ item }}
                                    </li>
                                </ul>
                            </div>
                            
                            <div class="d-flex position-relative w-75">
                              <span v-show="hasGenerated">Generated Password: <strong class="text-theme">{{user_data.password}}</strong></span>
                            </div>
                            <div class="d-flex justify-content-end gap-3 mb-3 align-items-center">
                              <input id="password" v-model="user_data.password" type="password"
                              placeholder="Password" class="w-100 border rounded py-2 px-4" required>

                              <button type="button" class="bg-theme text-light rounded p-2" @click="generate">Generate</button>
                            </div>
                            <div class="text-danger" v-show="errors.password">
                                <ul>
                                    <li v-for="item in errors.password" :key="item">
                                        {{ item }}
                                    </li>
                                </ul>
                            </div>
                            <input v-model="user_data.password_confirmation" type="password"
                             placeholder="Confirm Password" class="mb-3 w-100 border rounded py-2 px-4" required>
                            <div class="text-danger" v-show="errors.password_confirmation">
                                <ul>
                                    <li>
                                        {{ errors.password_confirmation }}
                                    </li>
                                </ul>
                            </div>
                            
                            <input type="text" value="Staff" class="mb-3 w-100 border rounded py-2 px-4" disabled>

                            <div class="text-danger" v-show="errors.role">
                                <ul>
                                    <li v-for="item in errors.role" :key="item">
                                        {{ item }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="w-100 d-flex justify-content-center">
                            <img src="../../../assets/imgs/undraw_file_analysis_8k9b.png" alt="create-account" class="w-50" style="object-fit: contain;">
                        </div>
                    </div>
                </div>
                <div class="card py-3 px-4">
                    <div class="ms-auto">
                        <button type="submit" name="register" class="bg-theme text-white py-2 px-5 rounded border-0">Create</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped src='../../../assets/css/inner.css'></style>

<script>
import PreloaderVue from "../../../components/Preloader.vue";
import { createNamespacedHelpers } from "vuex";
createNamespacedHelpers('user')

export default {
  name: 'CreateAccount',
  components: { PreloaderVue },
  data() {
      return{
        user_data: {
            full_name: '',
            username: '',
            email: '',
            password: '',
            password_confirmation: '',
            department: '1',
            role: '3'
        },
        hasGenerated: false,
      }
  },
  beforeMount() {
      if(!this.$store.getters['get_perm']('spartialUser')) this.$router.push('/dashboard')
  },
  mounted() {
    if(this.$store.state.user.user_data.username) this.$store.commit('user/reset_user_data')
    if(this.errors) this.$store.commit('user/reset_errors')
    if(this.message) this.$store.commit('user/reset_message')
  },
  methods: {
    async createAccount() {
      await this.$store.dispatch('user/createUser', this.user_data).then(() => {
        if(this.message.toLowerCase().includes('success')) {
            this.user_data = {
                full_name: '',
                username: '',
                email: '',
                password: '',
                password_confirmation: '',
                department: '1',
                role: '3',
            }
        }
      });
      
    },
    generate() {
        const keys = {
            upperCase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            lowerCase: 'abcdefghijklmnopqrstuvwxyz',
            number: '0123456789',
            symbol: '~!@#$%^&*()_+,.?<>'
        }
        const getKey = [
            function upperCase() {
                return keys.upperCase[Math.floor(Math.random() * keys.upperCase.length)];
            },
            function lowerCase() {
                return keys.lowerCase[Math.floor(Math.random() * keys.lowerCase.length)];
            },
            function number() {
                return keys.number[Math.floor(Math.random() * keys.number.length)];
            },
            function symbol() {
                return keys.symbol[Math.floor(Math.random() * keys.symbol.length)];
            }
        ]
        const max = 12
        var generated = ''

        while (max > generated.length) {
            let keyToAdd = getKey[Math.floor(Math.random() * getKey.length)];
            generated += keyToAdd();
        }
        
        const regexl = /[a-z]/;
        const regexc = /[A-Z]/;
        const regexn = /[0-9]/;

        if(!regexl.test(generated) || !regexc.test(generated) || !regexn.test(generated)) {
            this.generate()
        }
        else {
            this.user_data.password = generated
            this.user_data.password_confirmation = generated
            this.hasGenerated = true
        }
        
    },
  },
  computed: {
      errors: {
          get() {
              return this.$store.state.user.errors.errors
          }
      },
      message: {
          get() {
              return this.$store.state.user.message
          }
      },
      isLoading: {
          get() {
              return this.$store.state.user.isLoading
          }
      }
  }
}
</script>